html {
	overflow-x: hidden;
}

body {
	margin: 0;
	font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: black;
}

h1, p {
	margin: 0;
}

img {
	max-width: 100%;
}


/* width */
::-webkit-scrollbar {
	width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
	/* background: #000000d1; */
	background: #fff;
}